import { EquipmentGuard } from './../shared/guards/EquipmentGuard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { LoadingMedinboxComponent } from './loading-medinbox/loading-medinbox.component';
import { EquipmentMedinboxComponent } from './equipment-medinbox/equipment-medinbox.component';
import { StartLiveComponent } from './start-live/start-live.component';
import { UpcomingEventCardComponent } from '../main/home/upcoming-event-card/upcoming-event-card.component';
import { CalendarComponent } from '../shared/components/calendar/calendar.component';

const routes: Routes = [
  {
    path: 'load',
    // component: LoadingMedinboxComponent,
    loadChildren: () => import('./loading-medinbox/loading-medinbox.module').then((m) => m.LoadingMedinboxModule),
  },
  {
    path: 'home',
    // component: EquipmentMedinboxComponent,
    loadChildren: () => import('./equipment-medinbox/equipment-medinbox.module').then((m) => m.EquipmentMedinboxModule),
    canActivate: [EquipmentGuard],
  },
  {
    path: 'start',
    // component: EquipmentMedinboxComponent,
    loadChildren: () => import('./start-live/start-live.module').then((m) => m.StartLiveModule),
    canActivate: [EquipmentGuard],
  },
];

@NgModule({
  declarations: [LoadingMedinboxComponent, EquipmentMedinboxComponent, StartLiveComponent],
  imports: [CommonModule, SharedModule, RouterModule.forChild(routes), UpcomingEventCardComponent, CalendarComponent],
})
export class MedinboxModule {}
